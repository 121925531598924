var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("AConfirmation", {
        attrs: { isShow: _vm.isPending },
        on: { cancel: _vm.cancelSubmit, confirm: _vm.submit },
      }),
      _vm.api.isLoading && _vm.isFirstLoading
        ? _c("v-skeleton-loader", { ref: "skeleton", attrs: { type: "table" } })
        : _c("v-data-table", {
            staticClass: "elevation-1",
            attrs: {
              items: _vm.data.data,
              lazy: true,
              loading: _vm.api.isLoading,
              options: _vm.options,
              headers: _vm.dataHeader,
              "items-per-page": _vm.options.itemsPerPage,
              "server-items-length": _vm.data.total,
              search: _vm.search,
              "item-key": "user_id",
              "sort-desc:sync": _vm.options.sortDesc,
              "sort-by": _vm.options.sortBy,
              "footer-props": {
                itemsPerPageOptions: [20, 40, 100],
                showCurrentPage: true,
              },
            },
            on: {
              "update:options": [
                function ($event) {
                  _vm.options = $event
                },
                _vm.onPaginationUpdate,
              ],
              "update:itemsPerPage": function ($event) {
                return _vm.$set(_vm.options, "itemsPerPage", $event)
              },
              "update:items-per-page": function ($event) {
                return _vm.$set(_vm.options, "itemsPerPage", $event)
              },
              "update:sortBy": function ($event) {
                return _vm.$set(_vm.options, "sortBy", $event)
              },
              "update:sort-by": function ($event) {
                return _vm.$set(_vm.options, "sortBy", $event)
              },
            },
            scopedSlots: _vm._u([
              {
                key: "item.index",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          (_vm.options.page - 1) * _vm.options.itemsPerPage +
                            (_vm.data.data.indexOf(item) + 1)
                        ) +
                        " "
                    ),
                  ]
                },
              },
              {
                key: "top",
                fn: function () {
                  return [
                    _c(
                      "v-toolbar",
                      {
                        staticClass: "text-h4 pt-4",
                        attrs: { flat: "", height: "auto" },
                      },
                      [
                        _c(
                          "v-row",
                          { attrs: { "no-gutters": "" } },
                          [
                            _c(
                              "v-col",
                              { staticClass: "mt-3 mx-3" },
                              [
                                _c(
                                  "v-row",
                                  { staticClass: "mb-3" },
                                  [
                                    _c(
                                      "v-toolbar-title",
                                      {
                                        staticClass:
                                          "font-weight-bold text-h4 my-3",
                                      },
                                      [_vm._v(" Invoice list ")]
                                    ),
                                    _c("v-spacer"),
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "mx-3 mt-1",
                                        attrs: { color: "primary" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.fetch()
                                          },
                                        },
                                      },
                                      [_vm._v(" Refresh ")]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        dense: "",
                                        label: "Search",
                                        outlined: "",
                                        clearable: "",
                                      },
                                      on: { input: _vm.handleSearchInput },
                                      model: {
                                        value: _vm.search,
                                        callback: function ($$v) {
                                          _vm.search = $$v
                                        },
                                        expression: "search",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "item.company_name",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "PageCompaniesDetail",
                            params: { id: item.company_id },
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(item.company_name) + " ")]
                    ),
                  ]
                },
              },
              {
                key: "item.send_date",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.convertTimeZone(item.send_date)) + " "
                    ),
                  ]
                },
              },
            ]),
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }